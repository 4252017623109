
import { enumToOption } from '@/utils'

// 银行类型
export enum bankType {
  中国工商银行 =1,
  杭州银行,
}

// 是否默认
export enum isDefault {
  是 =1,
  否,
}

export const bankTypeOpt = enumToOption(bankType)
export const isDefaultOpt = enumToOption(isDefault)

// 表格配置
export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: false,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 是否开启索引
    isIndex: false,
    // table 索引 label
    indexLabel: '编号',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: false,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'bankType',
      label: '合作银行渠道',
      width: '80',
      formatter: (row, col, val) => {
        return bankType[val]
      }
    },
    {
      key: 'appIdName',
      label: 'APPID名称',
      width: '120'
    },
    {
      key: 'appId',
      label: 'APPID',
      width: '120'
    },
    {
      key: 'appIdSecretKey',
      label: 'APPID密钥',
      width: '120',
      formatter: (row, col, val) => {
        return val || '-'
      }
    },
    {
      key: 'isDefault',
      label: '自动默认',
      width: '50',
      formatter: (row, col, val) => {
        return isDefault[val] || '-'
      }
    },
    {
      key: 'action',
      label: '操作',
      width: '50',
      slot: 'action'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}
