
import { defineComponent, onMounted, ref, toRefs, watchEffect } from 'vue'
export default defineComponent({
  name: 'CTable',
  props: {
    // 传入数据
    dataSource: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rowKey: String
  },
  setup(props, { emit }) {
    let data = null

    const index = ref(1)

    watchEffect(() => {
      data = props.dataSource
      index.value = (data.pagination.currentPage - 1) * data.pagination.pageSize + 1
    })

    // table ref dom
    const cTableRef = ref<HTMLElement | null>(null)

    /**
     * @description: pageSize 改变时会触发
     * @param {number} val 每页条数
     * @return {void}
     */

    const pageSizeChange = (val: number) => {
      emit('pageSizeChange', val)
    }

    /**
     * @description: currentPage 改变时会触发
     * @param {number} val 当前页
     * @return {void}
     */
    const pageCurrentChange = (val: number) => {
      emit('pageCurrentChange', val)
    }

    /**
     * @description: 当表格的当前行发生变化的时候会触发该事件
     * @param {object} currentRow
     * @param {object} oldCurrentRow
     * @return {void}
     */
    const currentChange = (currentRow: object, oldCurrentRow: object) => {
      emit('currentChange', currentRow, oldCurrentRow)
    }

    /**
     * @description: 当选择项发生变化时会触发该事件
     * @param {*} selection
     * @return {void}
     */
    const selectionChange = (selection: []) => {
      emit('selectionChange', selection)
    }

    /**
     * @description: 当用户手动勾选全选 Checkbox 时触发的事件
     * @param {*} selection
     * @return {void}
     */
    const selectAll = (selection: []) => {
      emit('selectAll', selection)
    }

    /**
     * @description: 当表格的排序条件发生变化的时候会触发该事件
     * @param {*} column
     * @param {*} prop
     * @param {*} order
     * @return {void}
     */
    const sortChange = ({
      column,
      prop,
      order
    }: {
      column: object;
      prop: string;
      order: string;
    }) => {
      emit('sortChange', { column, prop, order })
    }

    onMounted(() => {
      // console.log(cTable.value);
    })

    return {
      ...toRefs(data),
      pageSizeChange,
      pageCurrentChange,
      currentChange,
      selectionChange,
      selectAll,
      sortChange,
      cTableRef,
      index
    }
  }
})
