
import { btnName } from '@/utils/index'
import { getListAPI } from './api'
import CTable from '@/components/c-table'
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { getTableConfig } from './config'
import { toResult } from '@/utils/toResult'
import Dialog from './components/dialog.vue'
import { usePagination } from '@/hooks/usePagination'
export default defineComponent({
  name: '',
  components: { CTable, Dialog },
  setup() {
    // 组件全局响应状态
    const state = reactive({
      dataSource: getTableConfig(),
      isShow: false,
      title: '',
      currentRow: {}
    })

    const getList = async () => {
      state.dataSource.loading = true
      const p = {
        currPage: state.dataSource.pagination.currentPage,
        pageSize: state.dataSource.pagination.pageSize
      }
      const [err, data] = await toResult(getListAPI(p))
      state.dataSource.loading = false
      if (err) return
      state.dataSource.data = data || []
    }

    // c-table pagination method hook
    const { pageCurrentChange, pageSizeChange } = usePagination(
      state.dataSource.pagination,
      () => {
        getList()
      }
    )

    const onToggle = (attr: string, row?, title?): void => {
      state[attr] = !state[attr]
      row && (state.currentRow = row)
      title && (state.title = title)
    }

    onMounted(() => {
      getList()
    })

    return {
      ...toRefs(state),
      btnName,
      pageCurrentChange,
      pageSizeChange,
      getList,
      onToggle
    }
  }
})
