/*
 * @Description:
 * @Author: yyx
 * @@后台人员: xxx
 * @Date: 2023-04-21 10:14:53
 * @LastEditors: yyx
 * @LastEditTime: 2023-04-24 13:39:28
 */
import service from '@/utils/request'

export const getListAPI = (data: any) => {
  return service.request({
    url: '/bankRelated/list',
    method: 'post',
    data
  })
}

export const addAPI = (data: any) => {
  return service.request({
    url: '/bankRelated/add',
    method: 'post',
    data
  })
}

export const editAPI = (data: any) => {
  return service.request({
    url: '/bankRelated/update',
    method: 'post',
    data
  })
}

// 企业配置收款渠道
export const bankConfigAPI = (data: any) => {
  return service.request({
    url: '/bankRelated/bankConfig',
    method: 'post',
    data
  })
}
